.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.site-title {
  color: #ffffff;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.title-logo {
  height: 40vh;
}

.small-logo {
  height: 10vh;
}

.home-background {
  height: 100%;
  background-color: #173451;

  background-size: 100%;
  background-repeat: no-repeat;
}

.global-background {
  background-image: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0.8) 0%,
      rgba(255, 255, 255, 0.8) 100%
    ),
    url(./images/summerland-logo-transparent-401.png);
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 70vh;
  background-color: #fbfcff;
}

.navbar {
  padding: 0.7rem;
  display: flex;
}

.navbar-nav {
  margin-left: auto;
}

.nav-item {
  margin: 0.5rem;
}

h3 {
  margin-left: 1rem;
}

.header {
  background-color: #173451;
}

/*  PRO SIDEBAR STYLES  */
.sidebar-header-arrow {
  cursor: pointer;
}

.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout,
.pro-sidebar .pro-menu {
  background-color: #173451;
  color: #f0f8ff;
}

.pro-sidebar .pro-menu li:hover {
  background-color: #44b4af;
}

.pro-sidebar .pro-menu .selectedOption {
  background-color: #204d78;
  color: #f0f8ff;
}

.pro-sidebar
  .pro-menu.shaped
  .pro-menu-item
  > .pro-inner-item
  > .pro-icon-wrapper {
  background-color: #204d78;
  color: #f0f8ff;
}

.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item {
  background-color: #173451;
  color: #f0f8ff;
  padding-left: 55px;
}

.main-section {
  padding: 1rem;
}

/* toggle switch styles */
.react-switch-checkbox {
  height: 0;
  width: 0;
  visibility: hidden;
}

.react-switch-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 2rem;
  height: 1.1rem;
  background: grey;
  border-radius: 100px;
  position: relative;
}

.react-switch-label .react-switch-button {
  content: "";
  position: absolute;
  top: 0.1rem;
  left: 0.1rem;
  width: 0.9rem;
  height: 0.9rem;
  border-radius: 1rem;
  background: rgb(219, 252, 218);
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
}

.react-switch-checkbox:checked + .react-switch-label .react-switch-button {
  left: calc(100% - 0.1rem);
  transform: translateX(-100%);
}

.react-switch-label:active .react-switch-button {
  width: 1.9rem;
}
